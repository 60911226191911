import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

import { Parallax, Pagination, Navigation, Autoplay } from "swiper/modules";

export const WinnerSlider = ({ visibility = true }) => {
  const awsurl = "https://eventimages2.s3.ap-south-1.amazonaws.com/winners/";
  const images = [
    "1. Prof. Jayanetti Koralalage Don Sumedha Jayanetti.jpg",
    "2. Prof. Samitha Deraniyagala.jpg",
    "3. Prof. Muthuwahandi Lalith de Silva.jpg",
    "4. Prof. Y.K.Weerakoon Banda.jpg",
    "5. Prof. Ruwan Jayathilaka.jpg",
    "6. Prof. Potupitiya Gamaathige Sanjeevani Amila Jayarathne.jpg",
    "7. Prof. Kamalanath Priyantha Hewagamage.jpg",
    "8. Prof. Sanath Jayasena.jpg",
    "9. Prof. Shironica Karunanayaka.jpg",
    "10. Prof. Lakshman Wedikkarage.jpg",
    "11. Prof. K B S N Jinadasa.jpg",
    "12. Prof. Meegoda Kankanange Dona Lalitha Meegoda.jpg",
    "13. Prof. Thamara Amarasekara.jpg",
    "14. Prof. Sudath Warnakulasuriya.jpg",
    "15. Dr. Harsha Wijesekera.jpg",
    "16. Prof. Naazima Kamardeen.jpg",
    "17. Prof. Sarath Peiris.jpg",
    "18. Dr. Chulani Herath.jpg",
    "19. Dr. Himal Suranga Jayasena.jpg",
  ];
  const awardsDetails = [
    {
      category: "Engineering",
      award: "Gold",
      name: "Prof. K B S N Jinadasa",
      image: "11. Prof. K B S N Jinadasa.jpg",
    },
    {
      category: "Applied Sciences",
      award: "Gold",
      name: "Prof. Jayanetti Koralalage Don Sumedha Jayanetti",
      image: "1. Prof. Jayanetti Koralalage Don Sumedha Jayanetti.jpg",
    },
    {
      category: "Applied Sciences",
      award: "Silver",
      name: "Prof. Samitha Deraniyagala",
      image: "2. Prof. Samitha Deraniyagala.jpg",
    },
    {
      category: "Architecture",
      award: "Gold",
      name: "Prof. Muthuwahandi Lalith de Silva",
      image: "3. Prof. Muthuwahandi Lalith de Silva.jpg",
    },
    {
      category: "Business and Management",
      award: "Gold",
      name: "Prof. Y.K.Weerakoon Banda",
      image: "4. Prof. Y.K.Weerakoon Banda.jpg",
    },
    {
      category: "Business and Management",
      award: "Silver",
      name: "Prof. Ruwan Jayathilaka",
      image: "5. Prof. Ruwan Jayathilaka.jpg",
    },
    {
      category: "Business and Management",
      award: "Bronze",
      name: "Prof. Potupitiya Gamaathige Sanjeevani Amila Jayarathne",
      image: "6. Prof. Potupitiya Gamaathige Sanjeevani Amila Jayarathne.jpg",
    },
    {
      category: "Nursing and Health Sciences",
      award: "Gold",
      name: "Prof. Meegoda Kankanange Dona Lalitha Meegoda",
      image: "12. Prof. Meegoda Kankanange Dona Lalitha Meegoda.jpg",
    },
    {
      category: "Nursing and Health Sciences",
      award: "Silver",
      name: "Prof. Thamara Amarasekara",
      image: "13. Prof. Thamara Amarasekara.jpg",
    },
    {
      category: "Nursing and Health Sciences",
      award: "Bronze",
      name: "Prof. Sudath Warnakulasuriya",
      image: "14. Prof. Sudath Warnakulasuriya.jpg",
    },
    {
      category: "Education",
      award: "Gold",
      name: "Prof. Shironica Karunanayaka",
      image: "9. Prof. Shironica Karunanayaka.jpg",
    },
    {
      category: "Education",
      award: "Silver",
      name: "Prof. Lakshman Wedikkarage",
      image: "10. Prof. Lakshman Wedikkarage.jpg",
    },
    {
      category: "Language and Literature",
      award: "Gold",
      name: "Dr. Harsha Dulari Wijesekera",
      image: "15. Dr. Harsha Wijesekera.jpg",
    },
    {
      category: "Psychology & Counselling",
      award: "Gold",
      name: "Dr. Chulani Jayasrimalee Herath",
      image: "18. Dr. Chulani Herath.jpg",
    },
    {
      category: "Computing",
      award: "Gold",
      name: "Prof. Kamalanath Priyantha Hewagamage",
      image: "7. Prof. Kamalanath Priyantha Hewagamage.jpg",
    },
    {
      category: "Computing",
      award: "Silver",
      name: "Prof. Sanath Jayasena",
      image: "8. Prof. Sanath Jayasena.jpg",
    },
    {
      category: "Mathematics and Statistics",
      award: "Gold",
      name: "Prof. Sarath Peiris",
      image: "17. Prof. Sarath Peiris.jpg",
    },
    {
      category: "Quantity Surveying",
      award: "Silver",
      name: "Dr. Himal Suranga Jayasena",
      image: "19. Dr. Himal Suranga Jayasena.jpg",
    },
    {
      category: "Law",
      award: "Gold",
      name: "Prof. Naazima Kamardeen",
      image: "16. Prof. Naazima Kamardeen.jpg",
    },
  ];

  return (
    <div
      className="flex flex-col h-full min-h-screen judges-bg-color py-10 lg:py-20 relative"
      id="judges"
    >
      <div className="text-center relative z-20">
        <h1
          className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] lg:my-0 px-4 lg:px-0 section-title relative mx-auto w-fit"
          style={{ fontFamily: "Bebas Neue" }}
          data-aos="fade-left"
          data-aos-delay="100"
        >
          WINNERS OF NATIONAL EDUCATOR AWARDS 2023
        </h1>
      </div>
      <div className="py-4 sm:py-28">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
            position: "absolute",
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
            type: "bullets",
            bulletClass: "swiper-pagination-bullet images-bullet",
          }}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Parallax, Pagination, Navigation, Autoplay]}
          className="judge-swiper winners-swiper h-[90vh] top-0 w-full"
          slidesPerView={1}
          spaceBetween={30}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {/* <div
            slot="container-start"
            className="parallax-bg-judge"
            data-swiper-parallax="-23%"
          ></div> */}
          {awardsDetails.map((data, index) => (
            // <div
            //   className="w-fit object-contain font-semibold bg-slate-100 rounded-md p-2.5 lg:p-4 flex flex-row justify-center items-center hover:cursor-pointer hover:text-white hover:bg-orange-500  transition-all hover:-translate-y-1"
            //   data-aos="fade-down"
            //   data-aos-delay={100 + 50 * index}
            //   key={data}
            // >
            <SwiperSlide>
              <div className="winner-card">
                <img
                  src={`${awsurl}${data.image}`}
                  alt={data.name}
                  className="w-[560px] rounded-lg h-80 object-cover"
                />
                <h2 className="text-white text-center font-medium text-xl lg:text-2xl mt-4 px-3">
                  {data.name || "Name not found"}
                </h2>
                <h4 className="text-gray-400 text-center font-medium text-base lg:text-lg mt-3">
                  {data.category} |{" "}
                  <span
                    className={`${
                      data.award === "Gold"
                        ? "text-yellow-600"
                        : data.award === "Silver"
                        ? "text-gray-500"
                        : "text-orange-700"
                    }`}
                  >
                    {data.award}
                  </span>
                </h4>
              </div>
            </SwiperSlide>
            // </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
