import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SquareCard = ({ data, index }) => {
  return (
    <div
      className="squrecard bg-slate-800/60 h-[125px] w-[125px] lg:h-[175px] lg:w-[175px] rounded-lg shadow-lg p-4 text-white text-center justify-evenly items-center flex flex-col transition-all border-2 border-slate-800/20 cursor-pointer hover:border-orange-500 hover:scale-105"
      data-aos="fade-up"
      data-aos-delay={100 + 50 * index}
    >
      <FontAwesomeIcon icon={data.icon} size="2xl" className="icon" />
      <h4 className="font-medium uppercase text-xs md:text-md">{data.name}</h4>
    </div>
  );
};

export default SquareCard;
