import React from "react";
import WinnerCard from "../components/common/winnerCard";

export const Winners = () => {
  const awards2023 = [
    {
      category: "Engineering",
      award: "gold",
      name: "Prof. K B S N Jinadasa",
      image: "11. Prof. K B S N Jinadasa.jpg",
      description:
        "Professor in Civil Engineering, Department of Civil Engineering, University of Peradeniya",
    },
    {
      category: "Applied Sciences",
      award: "gold",
      name: "Prof. Jayanetti Koralalage Don Sumedha Jayanetti",
      image: "1. Prof. Jayanetti Koralalage Don Sumedha Jayanetti.jpg",
      description:
        "Retired Senior Professor in Physics, Department of Instrumentation and Automation Technology, Faculty of Technology, University of Colombo. Former Dean, Faculty of Technology, University of Colombo.",
    },
    {
      category: "Applied Sciences",
      award: "silver",
      name: "Prof. Samitha Deraniyagala",
      image: "2. Prof. Samitha Deraniyagala.jpg",
      description:
        "Emeritus Professor, Department of Chemistry, University of Sri Jayewardenepura",
    },
    {
      category: "Architecture",
      award: "gold",
      name: "Prof. Muthuwahandi Lalith de Silva",
      image: "3. Prof. Muthuwahandi Lalith de Silva.jpg",
      description:
        "Head, School of Architecture, Sri Lanka Institute of Information Technology",
    },
    {
      category: "Business and Management",
      award: "gold",
      name: "Prof. Y.K.Weerakoon Banda",
      image: "4. Prof. Y.K.Weerakoon Banda.jpg",
      description:
        "Professor, Department of Business Management, Business School, Sri Lanka Institute of Information Technology",
    },
    {
      category: "Business and Management",
      award: "silver",
      name: "Prof. Ruwan Jayathilaka",
      image: "5. Prof. Ruwan Jayathilaka.jpg",
      description:
        "Professor/Head, Department of Information Management, Business School, Sri Lanka Institute of Information Technology",
    },
    {
      category: "Business and Management",
      award: "bronze",
      name: "Prof. Potupitiya Gamaathige Sanjeevani Amila Jayarathne",
      image: "6. Prof. Potupitiya Gamaathige Sanjeevani Amila Jayarathne.jpg",
      description:
        "Professor, Department of Business Management, Business School, Sri Lanka Institute of Information Technology",
    },
    {
      category: "Nursing and Health Sciences",
      award: "gold",
      name: "Prof. Meegoda Kankanange Dona Lalitha Meegoda",
      image: "12. Prof. Meegoda Kankanange Dona Lalitha Meegoda.jpg",
      description:
        "Professor, Department of Nursing and Midwifery, Faculty of Allied Health Sciences, University of Sri Jayewardenepura",
    },
    {
      category: "Nursing and Health Sciences",
      award: "silver",
      name: "Prof. Thamara Amarasekara",
      image: "13. Prof. Thamara Amarasekara.jpg",
      description:
        "Professor, Department of Nursing and Midwifery, Faculty of Allied Health Sciences, University of Sri Jayewardenepura",
    },
    {
      category: "Nursing and Health Sciences",
      award: "bronze",
      name: "Prof. Sudath Warnakulasuriya",
      image: "14. Prof. Sudath Warnakulasuriya.jpg",
      description: "Dean, Faculty of Nursing, University of Colombo",
    },
    {
      category: "Education",
      award: "gold",
      name: "Prof. Shironica Karunanayaka",
      image: "9. Prof. Shironica Karunanayaka.jpg",
      description:
        "Senior Professor in Educational Technology, Department of Secondary & Tertiary Education, Faculty of Education, The Open University of Sri Lanka (OUSL)",
    },
    {
      category: "Education",
      award: "silver",
      name: "Prof. Lakshman Wedikkarage",
      image: "10. Prof. Lakshman Wedikkarage.jpg",
      description:
        "Professor, Department of Social Science Education, Faculty of Education, University of Colombo",
    },
    {
      category: "Language and Literature",
      award: "gold",
      name: "Dr. Harsha Dulari Wijesekera",
      image: "15. Dr. Harsha Wijesekera.jpg",
      description:
        "Visiting Fellow, School of Literature Languages and Linguistics, Australian National University. Senior Lecturer, Postgraduate Institute of English, The Open University of Sri Lanka (OUSL)",
    },
    {
      category: "Psychology & Counselling",
      award: "gold",
      name: "Dr. Chulani Jayasrimalee Herath",
      image: "18. Dr. Chulani Herath.jpg",
      description:
        "Head, Department of Psychology and Counselling, Faculty of Health Sciences, The Open University of Sri Lanka",
    },
    {
      category: "Computing",
      award: "gold",
      name: "Prof. Kamalanath Priyantha Hewagamage",
      image: "7. Prof. Kamalanath Priyantha Hewagamage.jpg",
      description:
        "Senior Professor in Computer Science, University of Colombo School of Computing",
    },
    {
      category: "Computing",
      award: "silver",
      name: "Prof. Sanath Jayasena",
      image: "8. Prof. Sanath Jayasena.jpg",
      description:
        "Associate Professor, Department of Computer Science & Engineering, Faculty of Engineering, University of Moratuwa",
    },
    {
      category: "Mathematics and Statistics",
      award: "gold",
      name: "Prof. Sarath Peiris",
      image: "17. Prof. Sarath Peiris.jpg",
      description:
        "Head, Department of Mathematics and Statistics, Faculty of Humanities and Sciences, Sri Lanka Institute of Information Technology",
    },
    {
      category: "Quantity Surveying",
      award: "silver",
      name: "Dr. Himal Suranga Jayasena",
      image: "19. Dr. Himal Suranga Jayasena.jpg",
      description: "Senior Lecturer, University of Moratuwa",
    },
    {
      category: "Law",
      award: "gold",
      name: "Prof. Naazima Kamardeen",
      image: "16. Prof. Naazima Kamardeen.jpg",
      description:
        "Professor, Department of Commercial Law, Faculty of Law, University of Colombo",
    },
  ];

  return (
    <>
      <div className="w-full h-full min-h-screen py-10 lg:py-6 relative">
        <h1
          className="text-3xl font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20"
          style={{ fontFamily: "Poppins" }}
          data-aos="fade-down"
          data-aos-delay="100"
        >
          PAST WINNERS
        </h1>
        <div className="flex max-w-screen-2xl mt-10 flex-col md:flex-row mx-auto">
          <div className="min-w-[140px] flex flex-col border-b-2 md:border-b-0 md:border-r-2 border-gray-600 px-4 py-4 md:py-2 h-full md:h-[720px]">
            <h3 className="font-semibold text-lg md:text-xl text-white mb-4">
              Year
            </h3>
            <div className="flex w-full flex-row md:flex-col gap-3">
              {/* <button className="rounded-md border-2 border-orange-400 w-28 px-2 py-1 text-orange-400 text-lg font-medium bg-none">
                2024
              </button> */}
              <button className="rounded-md border-2 border-orange-400 w-full max-w-28 px-2 py-1 text-white text-base md:text-lg font-medium bg-orange-600">
                2023
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full py-4 px-8 gap-8">
            <div className="gold">
              <h1 className="font-semibold text-2xl md:text-[28px] text-[#e99e00]">
                Gold Winners
              </h1>
              <div className="flex justify-center lg:justify-start items-stretch flex-row flex-wrap w-full gap-8 mt-8">
                {awards2023
                  .filter((data) => data.award === "gold")
                  .map((data, index) => {
                    return <WinnerCard data={data} index={index} />;
                  })}
              </div>
            </div>
            <div className="silver">
              <h1 className="font-semibold text-2xl md:text-[28px] text-gray-400">
                Silver Winners
              </h1>
              <div className="flex justify-center lg:justify-start items-stretch flex-row flex-wrap w-full gap-8 mt-8">
                {awards2023
                  .filter((data) => data.award === "silver")
                  .map((data, index) => {
                    return <WinnerCard data={data} index={index} />;
                  })}
              </div>
            </div>
            <div className="bronze">
              <h1 className="font-semibold text-2xl md:text-[28px] text-orange-800">
                Bronze Winners
              </h1>
              <div className="flex justify-center lg:justify-start items-stretch flex-row flex-wrap w-full gap-8 mt-8">
                {awards2023
                  .filter((data) => data.award === "bronze")
                  .map((data, index) => {
                    return <WinnerCard data={data} index={index} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
