import React from "react";

export const ContactUs = () => {
  return (
    <div
      className="flex flex-col h-full bg-slate-900 category-bg-color contactus-bg"
      id="contactus"
    >
      <div className="w-full max-w-[1280px] mx-auto py-6 lg:py-16 flex flex-col sm:flex-row justify-between items-start relative z-30 px-6">
        <h3 className="text-white">
          <b className="text-orange-500">NATIONAL EDUCATOR AWARDS</b> <br />
          SLIIT Innovate,
          <br /> New Kandy Road, <br />
          Malabe, <br />
          Sri Lanka
        </h3>
        <div className="text-white">
          <p>
            Email:{" "}
            <a
              className="text-orange-500"
              href="mailto:info@nationaleducatorawards.lk"
            >
              info@nationaleducatorawards.lk
            </a>{" "}
          </p>
          <p>
            Phone:
            <span className="text-orange-500">
              {" "}
              <a href="tel:+94117543945">+94 11 754 39 45</a> /{" "}
              <a href="tel:+94773191815">+94 77 319 18 15</a>
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
