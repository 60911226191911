import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

import { Parallax, Pagination, Navigation, Autoplay } from "swiper/modules";

export const JudgeSlider = ({ visibility = true }) => {
  const judges = [
    ["University of Colombo", "colombo.png"],
    ["University of Moratuwa", "mora.jpg"],
    ["University of Peradeniya", "pera.png"],
    ["Informatics Institute of Technology", "iit.png"],
    ["Rajarata University of Sri Lanka", "rajarata.png"],
    ["Univeristy of Kelaniya", "kelaniya.png"],
    ["University of Ruhuna", "ruhuna.jpg"],
    ["General Sir John Kotelawala Defence Univeristy", "kdu.png"],
    ["Sri Lanka Institute of Information Technology", "sliit.png"],
    ["University of Sri Jayewardenepura", "japura.jpg"],
    ["Eastern University of Sri Lanka", "eastern.png"],
    ["National University of Singapaore (NUS)", "nus.png"],
  ];
  return (
    <div
      className="flex flex-col justify-between h-full min-h-screen judges-bg-color py-10 lg:py-20 relative"
      id="judges"
    >
      <div className="text-center relative z-20">
        <h1
          className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-10 lg:my-0 px-4 lg:px-0 section-title relative mx-auto w-fit"
          style={{ fontFamily: "Bebas Neue" }}
          data-aos="fade-left"
          data-aos-delay="100"
        >
          ESTEEMED PANEL OF JUDGES
          <br /> FROM THE FOLLOWING UNIVERSITIES
        </h1>
      </div>
      <div className="py-6 sm:py-32">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
            position: "absolute",
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
            type: "fraction",
            bulletClass: "judge-pagination-bullet",
            totalClass: "judge-pagination-total",
          }}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Parallax, Pagination, Navigation, Autoplay]}
          className="judge-swiper h-screen top-0 w-full"
        >
          <div
            slot="container-start"
            className="parallax-bg-judge"
            data-swiper-parallax="-23%"
          ></div>
          {judges.map((judge, index) => (
            <div
              className="w-fit object-contain font-semibold bg-slate-100 rounded-md p-2.5 lg:p-4 flex flex-row justify-center items-center hover:cursor-pointer hover:text-white hover:bg-orange-500  transition-all hover:-translate-y-1"
              data-aos="fade-down"
              data-aos-delay={100 + 50 * index}
              key={judge[0]}
            >
              <SwiperSlide>
                <div
                  className="text-3xl md:text-5xl text-center text-white font-bold p-20"
                  data-swiper-parallax="-300"
                >
                  {judge[0]}
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
