import React from "react";
import SquareCard from "./common/squreCard";
import {
  faBook,
  faBrain,
  faBriefcase,
  faBuilding,
  faCalculator,
  faComputer,
  faHospital,
  faMicroscope,
  faRuler,
  faScaleBalanced,
  faStethoscope,
  faUserGraduate,
  faWrench,
  faEarthAmericas
} from "@fortawesome/free-solid-svg-icons";

export const AwardCategories = () => {
  const categories = [
    { name: "Applied Sciences", icon: faMicroscope },
    { name: "Architecture", icon: faBuilding },
    { name: "Business & Management", icon: faBriefcase },
    { name: "Computing", icon: faComputer },
    { name: "Education", icon: faUserGraduate },
    { name: "Engineering", icon: faWrench },
    { name: "Social Sciences", icon: faEarthAmericas },
    { name: "Language and Literature", icon: faBook },
    { name: "Law", icon: faScaleBalanced },
    { name: "Mathematics & Statistics", icon: faCalculator },
    { name: "Nursing and Health Sciences", icon: faStethoscope },
    { name: "Psychology and Counselling", icon: faBrain },
    { name: "Quantity Surveying", icon: faRuler },
  ];
  return (
    <div
      className="flex flex-col justify-center h-full min-h-screen bg-slate-900 category-bg-color py-10 lg:py-20"
      id="categories"
    >
      <div className="text-center relative z-20">
        <h1
          className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-10 lg:my-0 px-4 lg:px-0 section-title relative mx-auto w-fit"
          style={{ fontFamily: "Bebas Neue" }}
          data-aos="fade-left"
          data-aos-delay="100"
        >
          Award Categories for 2024
        </h1>
      </div>
      <div className="py-6 sm:py-32">
        <div className="mx-auto flex items-center gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:max-w-7xl flex-wrap justify-center px-4 lg:px-0 ">
          {categories.map((category, index) => (
            <SquareCard data={category} key={index} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
