import React from "react";

export const Steering = () => {
  const team = [
    {
      post: "Chairman",
      name: "Professor Rahula Attalage",
      image: "/assets/img/rahula.jpg",
      description: [
        "Pro Vice-Chancellor Academic,",
        "Sri Lanka Institute of Information Technology",
      ],
    },
    {
      name: "Professor K.S. Lasith Gunawardena",
      image: "/assets/img/lasith.jpg",
      description: [
        "Head, Department of Information Technology,",
        "University of Sri Jayewardenepura",
      ],
    },
    {
      name: "Major General Lal Gunasekara (Retired)",
      image: "/assets/img/gunasekara.jpg",
      description: [
        "Rector,",
        "Metropolitan Campus,",
        "General Sir John Kotelawala Defence University",
      ],
    },
    {
      name: "Dr. L. L. Ekanayake",
      image: "/assets/img/ekanayake.jpg",
      description: [
        "Department of Civil Engineering,",
        "University of Moratuwa",
      ],
    },
  ];

  return (
    <div
      className="flex flex-col h-full min-h-screen bg-slate-900 category-bg-color "
      id="steering"
    >
      <div className="max-w-[1280px] mx-auto py-6 lg:py-16">
        <div className="text-center lg:mx-8 flex justify-center flex-col my-6 items-center">
          <h1
            className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-2 lg:my-0 px-8 lg:px-0 section-title relative w-fit text-center"
            style={{ fontFamily: "Bebas Neue" }}
            data-aos="fade-left"
            data-aos-delay="100"
          >
            STEERING COMMITTEE
          </h1>
        </div>
        <div className="py-3 sm:py-8">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col sm:flex-row justify-center items-center sm:items-start gap-10">
            {team.map((member, index) => (
              <div className="w-full flex flex-col justify-center items-center">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-[200px] h-[250px] sm:w-[300px] sm:h-[400px] object-cover object-top rounded-lg"
                  data-aos="fade-right"
                  data-aos-delay="100"
                />
                <div className="mt-4 text-center flex flex-col justify-center items-center">
                  {member.post && (
                    <h1 className="text-lg font-bold sm:text-xl capitalize tracking-wider text-[#dd7538] my-0 px-8 lg:px-0 relative w-fit text-center">
                      {member.post}
                    </h1>
                  )}
                  <h1 className="text-lg font-bold sm:text-xl capitalize tracking-wider text-[#dd7538] my-0 px-8 lg:px-0 relative w-fit text-center">
                    {member.name}
                  </h1>
                  <div className="mt-2 lg:mt-4">
                    {member.description.map((desc, index) => (
                      <p
                        key={index}
                        className="text-white text-md lg:text-lg w-full px-2"
                        data-aos="fade-right"
                        data-aos-delay="100"
                        data-aos-once="true"
                      >
                        {desc}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
