import React from "react";
// import "./SocialMediaIcons.css"; // Create this CSS file for styling

const SocialMediaIcons = () => {
  return (
    <div className="social-media-icons">
      {/* Add your social media icons here */}
      {/* Example: */}
      <a href="https://www.facebook.com/nationaleducatorawards" target="_blank">
        <img src="/assets/img/facebook-icon.svg" alt="Facebook" />
      </a>
      {/* Add more icons as needed */}
    </div>
  );
};

export default SocialMediaIcons;
