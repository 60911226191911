import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-[#110909]">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 flex flex-row">
            <span className="sr-only">Your Company</span>
            <img className="h-10 w-auto" src="/assets/img/award.png" alt="" />
            <span className="text-sm ml-4 text-white">
              NATIONAL EDUCATOR
              <br />
              AWARDS 2024
            </span>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-200"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <HashLink
            to="/#categories"
            className="text-[15px] font-semibold leading-6 text-gray-100 capitalize"
          >
            CATEGORIES
          </HashLink>
          <HashLink
            to="/#judges"
            className="text-[15px] font-semibold leading-6 text-gray-100 capitalize"
          >
            JUDGES
          </HashLink>
          <HashLink
            to="/#aboutus"
            className="text-[15px] font-semibold leading-6 text-gray-100 capitalize"
          >
            ABOUT US
          </HashLink>
          <HashLink
            to="/#steering"
            className="text-[15px] font-semibold leading-6 text-gray-100 capitalize"
          >
            STEERING COMMITTEE
          </HashLink>
          <HashLink
            to="/#team"
            className="text-[15px] font-semibold leading-6 text-gray-100 capitalize"
          >
            OUR TEAM
          </HashLink>
          <Link
            to="/winners"
            className="text-[15px] font-semibold leading-6 text-gray-100 capitalize"
          >
            PAST WINNERS
          </Link>
          {/* <Link
            to="/apply"
            className="text-[15px] font-semibold leading-6 text-orange-500 capitalize"
          >
            APPLY NOW
          </Link> */}
        </Popover.Group>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#110909] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5 flex flex-row">
              <span className="sr-only">Your Company</span>
              <img className="h-10 w-auto" src="/assets/img/award.png" alt="" />
              <span className="text-sm ml-4 text-white">
                NATIONAL EDUCATOR
                <br />
                AWARDS 2024
              </span>
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="#categories"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 "
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Categories
                </a>
                <a
                  href="#judges"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 "
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Judges
                </a>
                <a
                  href="#aboutus"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 "
                  onClick={() => setMobileMenuOpen(false)}
                >
                  About Us
                </a>
                <a
                  href="#steering"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 "
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Steering Committee
                </a>
                <a
                  href="#team"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 "
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Our Team
                </a>
                <Link
                  to="/winners"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 "
                >
                  Past Winners
                </Link>
                {/* <Link
                  to="/apply"
                  onClick={() => setMobileMenuOpen(false)}
                  className="text-md font-semibold leading-10  text-orange-500 capitalize"
                >
                  APPLY NOW
                </Link> */}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
