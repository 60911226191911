import React from "react";

export const Team = () => {
  const team = [
    {
      name: "Professor Rahula Attalage",
      image: "/assets/img/rahula.jpg",
    },
    {
      name: "Dr. Malitha Wijesundara",
      image: "/assets/img/malitha.jpg",
    },
  ];

  return (
    <div
      className="flex flex-col h-full min-h-screen bg-slate-900 category-bg-color "
      id="team"
    >
      <div className="max-w-[1280px] mx-auto py-6 lg:py-16">
        <div className="text-center lg:mx-8 flex justify-center flex-col my-6 items-center">
          <h1
            className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-2 lg:my-0 px-8 lg:px-0 section-title relative w-fit text-center"
            style={{ fontFamily: "Bebas Neue" }}
            data-aos="fade-left"
            data-aos-delay="100"
          >
            Our Team
          </h1>
          <p
            className="mt-4 lg:mt-8 text-white text-md lg:text-lg w-full lg:w-3/4 px-8"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            NEA is driven by a dynamic team led by Professor Rahula Attalage and
            Dr. Malitha Wijesundara. Their leadership, academic expertise, and
            unwavering commitment to the project ensure its success and
            establish it as the benchmark for recognizing and honoring
            outstanding educators in Sri Lanka.
          </p>
        </div>
        <div className="py-3 sm:py-8">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col sm:flex-row justify-center items-center sm:items-start gap-10">
            {team.map((member, index) => (
              <div className="max-w-[300px] flex flex-col justify-center items-center">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-[200px] h-[250px] sm:w-[300px] sm:h-[400px] object-cover object-top rounded-lg my-4"
                  data-aos="fade-right"
                  data-aos-delay="100"
                />
                <h1 className="text-lg font-bold sm:text-xl capitalize tracking-wider text-[#dd7538] lg:my-0 px-8 lg:px-0 relative w-fit text-center">
                  {member.name}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
